import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddDocumentButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    // @ts-ignore
    variant="raised"
    component={Link}
    to={`/documents/create?user_product_id=${record.id}`}
    label="Add a document"
    title="Add a document"
  >
    <PictureAsPdfIcon />
  </Button>
);

export default withStyles(styles)(AddDocumentButton);
