import { Toolbar, SaveButton, useRecordContext } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import getHTML from "../pdf/generator";
import axios from "axios";

const toolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export const SavePDFToolbar = (props: any) => {
  const record = useRecordContext();
  console.log(record);

  const GetReactPageS3Location = async () => {
    let s3Key;
    const HTMLList = [];

    props.setReadOnly(true);

    const editors = await document.getElementsByClassName("MuiPaper-root");

    for (let i = 0; i < editors.length; i++) {
      const { html } = await getHTML(editors[i]);
      HTMLList[i] = html;
    }

    await axios
      .post(`${process.env.REACT_APP_API_URI}/pdf`, {
        html: HTMLList,
        id: record.id,
        landscape: record.orientation === "l" ? "true" : "false",
      })
      .then((result) => {
        s3Key = result.data;
      });

    return s3Key;
  };

  return (
    <Toolbar classes={toolbarStyles()} {...props}>
      <SaveButton
        disabled={props.pristine}
        type="button"
        transform={async (data) => ({
          ...data,
          s3_key: await GetReactPageS3Location(),
        })}
        submitOnEnter={false}
      />
    </Toolbar>
  );
};

export const OnlySaveToolbar = (props: any) => {
  return (
    <Toolbar classes={toolbarStyles()} {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
};
