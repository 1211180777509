import axios from "axios";
import { UserPoolId, ClientId } from "./auth/config";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const UserPool = new CognitoUserPool({
  UserPoolId,
  ClientId,
});

export const getToken = () => {
  const user = UserPool.getCurrentUser();
  if (!user) {
    return "";
  }

  const token =
    // @ts-ignore
    user.storage[
      // @ts-ignore
      `CognitoIdentityServiceProvider.${ClientId}.${user.username}.idToken`
    ];

  return token;
};

const axiosInstance = axios.create({
  baseUrl: process.env.REACT_APP_API_URI,
});

export default axiosInstance;
