import type { CellPlugin } from "@react-page/editor";

const containerStyle = {
  textAlign: "center",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  height: "8.5in",
  boxSizing: "border-box",
  marginRight: "50%",
};

const pageNumberStyle = {
  fontSize: "11px",
  fontWeight: 700,
  fontStyle: "italic",
};

const paginationBar: CellPlugin<{
  pageNumber: string;
  textColor: string;
  backgroundColor: string;
}> = {
  Renderer: ({ data }) => (
    <div
      style={{
        ...(containerStyle as any),
        background: `var(--rp-${data.backgroundColor}-color)`,
      }}
    >
      <div
        style={{
          ...pageNumberStyle,
          color: `var(--rp-${data.textColor}-color)`,
        }}
      >
        {data.pageNumber}
      </div>
    </div>
  ),
  id: "paginationBar",
  title: "Pagination bar",
  description: "Pagination vertical bar with text content.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        pageNumber: {
          type: "string",
          default: "Lorem ipsum",
        },
        textColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
        backgroundColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
      },
    },
  },
};

export default paginationBar;
