import type { CellPlugin } from "@react-page/editor";
import { FileUploadButton } from "../components/common/common";

const containerStyle = {
  height: "160px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  margin: "8px",
};

const overlayStyle = {
  position: "absolute",
  height: "160px",
  width: "100%",
  left: "0",
  top: "0",
  zIndex: "1",
  opacity: "0.6",
};

const colorImagePlugin: CellPlugin<{
  color: string;
  imageUrl: string;
  marginRight: number;
}> = {
  Renderer: ({ data }) => (
    <div
      style={
        { ...containerStyle, backgroundImage: `url("${data.imageUrl}")` } as any
      }
    >
      <div
        style={
          {
            ...overlayStyle,
            background: `var(--rp-${data.color}-color)`,
          } as any
        }
      />
    </div>
  ),
  id: "colorImagePlugin",
  title: "Image with color",
  description: "Add image with color superimposed.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: ["imageUrl", "color"],
      properties: {
        imageUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        marginRight: {
          type: "integer",
          default: 0,
        },
        color: {
          type: "string",
          enum: ["primary", "secondary", "tertiary"],
        },
      },
    },
  },
};

export default colorImagePlugin;
