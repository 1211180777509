export const Styles = `
.react-page-editable {
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  padding-right: 2px;
  padding-left: 0px;
  padding-left: 2px;
}
.react-page-row {
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: calc((0px * 0.5) * -1);
  margin-right: -0.5px;
  margin-left: calc((0px * 0.5) * -1);
  margin-left: -0.5px;
  position: relative;
}
.react-page-cell-xs,
.react-page-cell-xs-1,
.react-page-cell-xs-2,
.react-page-cell-xs-3,
.react-page-cell-xs-4,
.react-page-cell-xs-5,
.react-page-cell-xs-6,
.react-page-cell-xs-7,
.react-page-cell-xs-8,
.react-page-cell-xs-9,
.react-page-cell-xs-10,
.react-page-cell-xs-11,
.react-page-cell-xs-12,
.react-page-cell-xs-offset-0,
.react-page-cell-xs-offset-1,
.react-page-cell-xs-offset-2,
.react-page-cell-xs-offset-3,
.react-page-cell-xs-offset-4,
.react-page-cell-xs-offset-5,
.react-page-cell-xs-offset-6,
.react-page-cell-xs-offset-7,
.react-page-cell-xs-offset-8,
.react-page-cell-xs-offset-9,
.react-page-cell-xs-offset-10,
.react-page-cell-xs-offset-11,
.react-page-cell-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: calc((0px * 0.5));
  padding-right: 0.5px;
  padding-left: calc((0px * 0.5));
  padding-left: 0.5px;
  min-width: 0;
}
.react-page-cell-xs {
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  min-width: 100%;
}
.react-page-cell-xs-1 {
  flex-basis: 8.33333333%;
  width: 8.33333333%;
}
.react-page-cell-xs-2 {
  flex-basis: 16.66666667%;
  width: 16.66666667%;
}
.react-page-cell-xs-3 {
  flex-basis: 25%;
  width: 25%;
  min-width: 25%;
}
.react-page-cell-xs-4 {
  flex-basis: 33.33333333%;
  width: 33.33333333%;
}
.react-page-cell-xs-5 {
  flex-basis: 41.66666667%;
  width: 41.66666667%;
}
.react-page-cell-xs-6 {
  flex-basis: 50%;
  width: 50%;
  min-width: 50%;
}
.react-page-cell-xs-7 {
  flex-basis: 58.33333333%;
  width: 58.33333333%;
}
.react-page-cell-xs-8 {
  flex-basis: 66.66666667%;
  width: 66.66666667%;
}
.react-page-cell-xs-9 {
  flex-basis: 75%;
  width: 75%;
  min-width: 75%;
}
.react-page-cell-xs-10 {
  flex-basis: 83.33333333%;
  width: 83.33333333%;
}
.react-page-cell-xs-11 {
  flex-basis: 91.66666667%;
  width: 91.66666667%;
}
.react-page-cell-xs-12 {
  flex-basis: 100%;
  width: 100%;
  min-width: 100%;
}
.react-page-cell-xs-offset-0 {
  margin-left: 0;
}
.react-page-cell-xs-offset-1 {
  margin-left: 8.33333333%;
}
.react-page-cell-xs-offset-2 {
  margin-left: 16.66666667%;
}
.react-page-cell-xs-offset-3 {
  margin-left: 25%;
}
.react-page-cell-xs-offset-4 {
  margin-left: 33.33333333%;
}
.react-page-cell-xs-offset-5 {
  margin-left: 41.66666667%;
}
.react-page-cell-xs-offset-6 {
  margin-left: 50%;
}
.react-page-cell-xs-offset-7 {
  margin-left: 58.33333333%;
}
.react-page-cell-xs-offset-8 {
  margin-left: 66.66666667%;
}
.react-page-cell-xs-offset-9 {
  margin-left: 75%;
}
.react-page-cell-xs-offset-10 {
  margin-left: 83.33333333%;
}
.react-page-cell-xs-offset-11 {
  margin-left: 91.66666667%;
}
[dir="ltr"] .start-xs {
  text-align: left;
}
[dir="rtl"] .start-xs {
  text-align: right;
}
.start-xs {
  justify-content: flex-start;
}
.center-xs {
  justify-content: center;
  text-align: center;
}
[dir="ltr"] .end-xs {
  text-align: right;
}
[dir="rtl"] .end-xs {
  text-align: left;
}
.end-xs {
  justify-content: flex-end;
}
.top-xs {
  align-items: flex-start;
}
.middle-xs {
  align-items: center;
}
.bottom-xs {
  align-items: flex-end;
}
.around-xs {
  justify-content: space-around;
}
.between-xs {
  justify-content: space-between;
}
.first-xs {
  order: -1;
}
.last-xs {
  order: 1;
}
@media only screen and (min-width: 48em) {
  .react-page-cell-sm,
  .react-page-cell-sm-1,
  .react-page-cell-sm-2,
  .react-page-cell-sm-3,
  .react-page-cell-sm-4,
  .react-page-cell-sm-5,
  .react-page-cell-sm-6,
  .react-page-cell-sm-7,
  .react-page-cell-sm-8,
  .react-page-cell-sm-9,
  .react-page-cell-sm-10,
  .react-page-cell-sm-11,
  .react-page-cell-sm-12,
  .react-page-cell-sm-offset-0,
  .react-page-cell-sm-offset-1,
  .react-page-cell-sm-offset-2,
  .react-page-cell-sm-offset-3,
  .react-page-cell-sm-offset-4,
  .react-page-cell-sm-offset-5,
  .react-page-cell-sm-offset-6,
  .react-page-cell-sm-offset-7,
  .react-page-cell-sm-offset-8,
  .react-page-cell-sm-offset-9,
  .react-page-cell-sm-offset-10,
  .react-page-cell-sm-offset-11,
  .react-page-cell-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0px * 0.5));
    padding-right: 0.5px;
    padding-left: calc((0px * 0.5));
    padding-left: 0.5px;
    min-width: 0;
  }

  .react-page-cell-sm {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-sm-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }

  .react-page-cell-sm-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%;
  }

  .react-page-cell-sm-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%;
  }

  .react-page-cell-sm-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }

  .react-page-cell-sm-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%;
  }

  .react-page-cell-sm-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%;
  }

  .react-page-cell-sm-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }

  .react-page-cell-sm-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%;
  }

  .react-page-cell-sm-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%;
  }

  .react-page-cell-sm-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }

  .react-page-cell-sm-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%;
  }

  .react-page-cell-sm-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-sm-offset-0 {
    margin-left: 0;
  }

  .react-page-cell-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .react-page-cell-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .react-page-cell-sm-offset-3 {
    margin-left: 25%;
  }

  .react-page-cell-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .react-page-cell-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .react-page-cell-sm-offset-6 {
    margin-left: 50%;
  }

  .react-page-cell-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .react-page-cell-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .react-page-cell-sm-offset-9 {
    margin-left: 75%;
  }

  .react-page-cell-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .react-page-cell-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-sm {
    text-align: left;
    }

  [dir="rtl"] .start-sm {
    text-align: right;
    }

  .start-sm {
    justify-content: flex-start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-sm {
    text-align: right;
    }

  [dir="rtl"] .end-sm {
    text-align: left;
    }

  .end-sm {
    justify-content: flex-end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}
@media only screen and (min-width: 64em) {
  .react-page-cell-md,
  .react-page-cell-md-1,
  .react-page-cell-md-2,
  .react-page-cell-md-3,
  .react-page-cell-md-4,
  .react-page-cell-md-5,
  .react-page-cell-md-6,
  .react-page-cell-md-7,
  .react-page-cell-md-8,
  .react-page-cell-md-9,
  .react-page-cell-md-10,
  .react-page-cell-md-11,
  .react-page-cell-md-12,
  .react-page-cell-md-offset-0,
  .react-page-cell-md-offset-1,
  .react-page-cell-md-offset-2,
  .react-page-cell-md-offset-3,
  .react-page-cell-md-offset-4,
  .react-page-cell-md-offset-5,
  .react-page-cell-md-offset-6,
  .react-page-cell-md-offset-7,
  .react-page-cell-md-offset-8,
  .react-page-cell-md-offset-9,
  .react-page-cell-md-offset-10,
  .react-page-cell-md-offset-11,
  .react-page-cell-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0px * 0.5));
    padding-right: 0.5px;
    padding-left: calc((0px * 0.5));
    padding-left: 0.5px;
    min-width: 0;
  }

  .react-page-cell-md {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-md-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }

  .react-page-cell-md-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%;
  }

  .react-page-cell-md-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%;
  }

  .react-page-cell-md-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }

  .react-page-cell-md-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%;
  }

  .react-page-cell-md-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%;
  }

  .react-page-cell-md-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }

  .react-page-cell-md-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%;
  }

  .react-page-cell-md-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%;
  }

  .react-page-cell-md-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }

  .react-page-cell-md-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%;
  }

  .react-page-cell-md-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-md-offset-0 {
    margin-left: 0;
  }

  .react-page-cell-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .react-page-cell-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .react-page-cell-md-offset-3 {
    margin-left: 25%;
  }

  .react-page-cell-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .react-page-cell-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .react-page-cell-md-offset-6 {
    margin-left: 50%;
  }

  .react-page-cell-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .react-page-cell-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .react-page-cell-md-offset-9 {
    margin-left: 75%;
  }

  .react-page-cell-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .react-page-cell-md-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-md {
    text-align: left;
    }

  [dir="rtl"] .start-md {
    text-align: right;
    }

  .start-md {
    justify-content: flex-start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-md {
    text-align: right;
    }

  [dir="rtl"] .end-md {
    text-align: left;
    }

  .end-md {
    justify-content: flex-end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .react-page-cell-lg,
  .react-page-cell-lg-1,
  .react-page-cell-lg-2,
  .react-page-cell-lg-3,
  .react-page-cell-lg-4,
  .react-page-cell-lg-5,
  .react-page-cell-lg-6,
  .react-page-cell-lg-7,
  .react-page-cell-lg-8,
  .react-page-cell-lg-9,
  .react-page-cell-lg-10,
  .react-page-cell-lg-11,
  .react-page-cell-lg-12,
  .react-page-cell-lg-offset-0,
  .react-page-cell-lg-offset-1,
  .react-page-cell-lg-offset-2,
  .react-page-cell-lg-offset-3,
  .react-page-cell-lg-offset-4,
  .react-page-cell-lg-offset-5,
  .react-page-cell-lg-offset-6,
  .react-page-cell-lg-offset-7,
  .react-page-cell-lg-offset-8,
  .react-page-cell-lg-offset-9,
  .react-page-cell-lg-offset-10,
  .react-page-cell-lg-offset-11,
  .react-page-cell-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: calc((0px * 0.5));
    padding-right: 0.5px;
    padding-left: calc((0px * 0.5));
    padding-left: 0.5px;
    min-width: 0;
  }

  .react-page-cell-lg {
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-lg-1 {
    flex-basis: 8.33333333%;
    width: 8.33333333%;
  }

  .react-page-cell-lg-2 {
    flex-basis: 16.66666667%;
    width: 16.66666667%;
  }

  .react-page-cell-lg-3 {
    flex-basis: 25%;
    width: 25%;
    min-width: 25%;
  }

  .react-page-cell-lg-4 {
    flex-basis: 33.33333333%;
    width: 33.33333333%;
  }

  .react-page-cell-lg-5 {
    flex-basis: 41.66666667%;
    width: 41.66666667%;
  }

  .react-page-cell-lg-6 {
    flex-basis: 50%;
    width: 50%;
    min-width: 50%;
  }

  .react-page-cell-lg-7 {
    flex-basis: 58.33333333%;
    width: 58.33333333%;
  }

  .react-page-cell-lg-8 {
    flex-basis: 66.66666667%;
    width: 66.66666667%;
  }

  .react-page-cell-lg-9 {
    flex-basis: 75%;
    width: 75%;
    min-width: 75%;
  }

  .react-page-cell-lg-10 {
    flex-basis: 83.33333333%;
    width: 83.33333333%;
  }

  .react-page-cell-lg-11 {
    flex-basis: 91.66666667%;
    width: 91.66666667%;
  }

  .react-page-cell-lg-12 {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }

  .react-page-cell-lg-offset-0 {
    margin-left: 0;
  }

  .react-page-cell-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .react-page-cell-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .react-page-cell-lg-offset-3 {
    margin-left: 25%;
  }

  .react-page-cell-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .react-page-cell-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .react-page-cell-lg-offset-6 {
    margin-left: 50%;
  }

  .react-page-cell-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .react-page-cell-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .react-page-cell-lg-offset-9 {
    margin-left: 75%;
  }

  .react-page-cell-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .react-page-cell-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  [dir="ltr"] .start-lg {
    text-align: left;
    }

  [dir="rtl"] .start-lg {
    text-align: right;
    }

  .start-lg {
    justify-content: flex-start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  [dir="ltr"] .end-lg {
    text-align: right;
    }

  [dir="rtl"] .end-lg {
    text-align: left;
    }

  .end-lg {
    justify-content: flex-end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}
.react-page-row-has-floating-children {
  display: block !important;
}
.react-page-row-is-hovering-this\\:before {
  content: ' ';
  position: absolute;
  background: black;
  display: block;
  background: rgba(0, 0, 0, 0.87);
  background: black;
  z-index: 2;
}
.react-page-row-is-hovering-above\\:before {
  top: 0;
  height: 4px;
  width: 100%;
}
.react-page-row-is-hovering-below\\:before {
  bottom: 0;
  height: 4px;
  width: 100%;
}
.react-page-row-is-hovering-left-of\\:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}
.react-page-row-is-hovering-right-of\\:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}
@keyframes reactPageCellResizeableAnimate {
  0% {
    background-color: rgba(255, 255, 255, 0.26);
    background-color: white;
    top: 45%;
    bottom: 45%;
  }
  50% {
    background-color: rgba(255, 255, 255, 0.12);
    background-color: white;
    top: 0;
    bottom: 0;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.87);
    background-color: lightgrey;
    top: 45%;
    bottom: 45%;
  }
}
.react-page-row .resize-handle {
  position: absolute;
  z-index: 6;
  width: 4px;
  top: 0;
  bottom: 0;
  left: -2px;
  cursor: e-resize;
  background-color: rgba(0, 0, 0, 0.54);
  background-color: black;
  mix-blend-mode: difference;
}
.react-page-row .resize-handle\\:after {
  content: ' ';
  background-color: rgba(255, 255, 255, 0.87);
  background-color: lightgrey;
  /* width: 30px; */
  /* height: 30px; */
  position: absolute;
  /* z-index: 1000; */
  top: 40%;
  bottom: 40%;
  left: 0;
  right: 0;
  transition: all 200ms linear;
  mix-blend-mode: normal;

  animation-name: reactPageCellResizeableAnimate;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-duration: 0.8s;
}
.react-page-row .resize-handle\\:hover\\:after,
.react-page-row .resize-handle\\:active\\:after {
  background-color: rgba(255, 255, 255, 1);
  background-color: white;
  top: 0;
  bottom: 0;
}
.react-page-editable {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 560px;
  display: flex;
  flex: 0 1 auto;

  flex-direction: column;

  align-items: stretch;
}
.react-page-editable .react-page-cell-insert-new {
    cursor: cell;
    flex: 1 1;
    min-height: 100px;

    transition: all ease 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
    z-index: 1;
    position: relative;
  }
.react-page-editable .react-page-cell-insert-new\\:after {
    content: '+';
    font-size: 52px;
    font-family: monospace;
    line-height: 52px;
    background-color: rgba(0, 0, 0, 0.26);
    background-color: black;
    color: white;
    width: 52px;
    text-align: center;
    border-radius: 50%;
  }
.react-page-editable .react-page-cell-insert-new\\:hover,
  .react-page-editable .react-page-cell-insert-new.hover {
    background: rgba(0, 0, 0, 0.26);
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.54);
    border: 1px solid white;
  }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable {
      cursor: move;
      position: relative;
      transition: opacity 200ms ease;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      cursor: move;
      border: 1px solid rgba(0, 0, 0, 0);
      transition: all ease 0.4s;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
      position: absolute;
      top: 0;
      right: 0;
      width: 2px;
      height: 2px;
      cursor: move;
      background: rgba(0, 0, 0, 0.12);
      background: black;
      content: '';
      z-index: 500;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle {
      opacity: 0;
      transition: opacity 0.3s;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell\\:hover
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell\\:hover
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell\\:hover
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused
      > div
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay-handle {
      opacity: 1;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before {
      top: -300%;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon {
      width: 70%;
      top: 50%;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after {
      top: 400%;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after {
      width: 100%;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:before,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay-handle
      .react-page-cell-draggable-overlay-handle-icon\\:\\:after {
      height: 2px;
      position: absolute;
      content: '';
      transform: translate(-50%, -50%);
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell\\:hover
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-resizing .react-page-cell\\:hover
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay, .react-page-editable.react-page-editable-mode-layout .react-page-cell\\:hover
      > div
      > div
      > div
      > .react-page-cell-draggable-overlay {
      background: rgba(0, 0, 0, 0.12);
      background: black;
      border: 1px solid rgba(255, 255, 255, 0.54);
      border: 1px solid white;
      background-blend-mode: screen;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf, .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay.react-page-cell-draggable-leaf {
      z-index: 3;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-left.react-page-cell-draggable-overlay,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-inline-right.react-page-cell-draggable-overlay {
      float: left;
      z-index: 4;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable-in-edit,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable-in-edit,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-is-dragging,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-is-dragging
      + div
      > .react-page-cell-droppable
      > .react-page-cell-draggable-in-edit {
      opacity: 0.4;
      outline: none;
    }
.react-page-editable.react-page-editable-mode-layout .react-page-cell-draggable-overlay {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
.react-page-editable.react-page-editable-mode-layout .react-page-cell-move-actions {
      opacity: 0;
      transition: 0.6s;
    }
.react-page-editable.react-page-editable-mode-layout .react-page-cell\\:hover > .react-page-cell-move-actions {
      opacity: 0.9;
    }
.react-page-cell-droppable {
  /*position: relative;*/
}
.react-page-cell-droppable-is-over-current\\:after {
  /* extend dropable area a bit, so that its easier to add as last eleent */
  content: '';
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: -100px;
}
.react-page-cell-droppable-is-over-current\\:before {
  content: ' ';
  display: block;
  position: absolute;
  z-index: 1;
  background: rgba(0, 0, 0, 0.87);
  background: black;
}
.react-page-cell-droppable-is-over-above\\:before,
.react-page-cell-droppable-is-over-below\\:before {
  border-left: 0;
  border-right: 0;
}
.react-page-cell-droppable-is-over-left-of\\:before,
.react-page-cell-droppable-is-over-right-of\\:before {
  border-top: 0;
  border-bottom: 0;
}
.react-page-cell-droppable-is-over-above\\:before {
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  border-left: 0;
  border-right: 0;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-above\\:before {
  height: 4px;
}
.react-page-cell-droppable-is-over-below\\:before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-below\\:before {
  height: 4px;
}
.react-page-cell-droppable-is-over-left-of\\:before {
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-left-of\\:before {
  width: 4px;
}
.react-page-cell-droppable-is-over-right-of\\:before {
  top: 0;
  right: 0;
  bottom: 0;
  width: 4px;
}
.react-page-cell-droppable-leaf.react-page-cell-droppable-is-over-right-of\\:before {
  width: 4px;
}
.react-page-cell-droppable-is-over-inline-right\\:before,
.react-page-cell-droppable-is-over-inline-left\\:before {
  top: 0;
  width: 50%;
  height: 36%;
  max-height: 128px;
  background-color: #e0e0e0;
  background-color: grey;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border: 1px solid black;
}
.react-page-cell-droppable-is-over-inline-left\\:before {
  left: 0;
}
.react-page-cell-droppable-is-over-inline-right\\:before {
  right: 0;
}
.react-page-cell-droppable-not-allowed {
  cursor: not-allowed;
}
.react-page-cell-error {
  background-color: red;
  padding: 8px;
  margin: 2px;
  overflow: hidden;
}
.react-page-cell-error strong {
  margin: 0 auto;
}
.react-page-cell-error code {
  overflow: scroll;
}
.react-page-editable .react-page-cell-handle {
    display: none;
  }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      transition: opacity ease 0.4s;
      opacity: 0;

      color: rgba(0, 0, 0, 0.97);

      background: rgba(255, 255, 255, 0.95);
      text-align: center;
      color: rgba(0, 0, 0, 0.97);

      display: inline-block;
      padding: 12px 24px;
      margin: 0 auto;
      border-radius: 12px 12px 0 0;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.15em;

      box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.22);
      pointer-events: none;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-handle-drag-enabled, .react-page-editable.react-page-editable-mode-layout .react-page-cell-handle-drag-enabled {
      cursor: move;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell\\:hover > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell\\:hover > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell\\:hover > .react-page-cell-handle,
    .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused > .react-page-cell-handle {
      opacity: 1;
      pointer-events: all;
    }
/* general rules for read only and edit */
.react-page-cell {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.react-page-cell-inline-left {
    float: left;
  }
.react-page-cell-inline-right {
    float: right;
  }
.react-page-cell-has-inline-neighbour {
    float: none;
    width: 100%;
    display: block;
  }
.react-page-cell .react-page-cell-inner {
    height: 100%;
    box-sizing: border-box;
    outline: none;
  }
/* edit */
.react-page-editable.react-page-editable-mode-edit .react-page-cell, .react-page-editable.react-page-editable-mode-resizing .react-page-cell, .react-page-editable.react-page-editable-mode-layout .react-page-cell {
      position: relative;
      transition: box-shadow 0.3s;
    }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-is-draft, .react-page-editable.react-page-editable-mode-layout .react-page-cell-is-draft {
        opacity: 0.3;
        outline: 1px dashed black;
      }
.react-page-editable.react-page-editable-mode-edit .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-resizing .react-page-cell-bring-to-front, .react-page-editable.react-page-editable-mode-layout .react-page-cell-bring-to-front {
        z-index: 3;
      }
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-has-plugin\\:hover, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-has-plugin\\:hover, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-has-plugin\\:hover {
        z-index: 2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
.react-page-editable.react-page-editable-mode-edit .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-resizing .react-page-cell.react-page-cell-focused.react-page-cell-focused, .react-page-editable.react-page-editable-mode-layout .react-page-cell.react-page-cell-focused.react-page-cell-focused {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
.react-page-editable.react-page-editable-mode-insert .react-page-cell {
      cursor: cell;
    }
.react-page-editable.react-page-editable-mode-insert .react-page-cell\\:hover {
        z-index: 1;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
        opacity: 1;
      }
.react-page-controls-mode-toggle-button-inner {
  float: right;
  margin: 8px;
}
.react-page-controls-mode-toggle-button-description {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  margin-top: 18px;
  float: right;
  background: transparent;
  color: transparent;
  border: 1px transparent solid;
  padding: 2px 8px;
  text-align: right;
  display: none;
  transition: all 200ms ease;
  white-space: nowrap;
  overflow: hidden;
}
.react-page-controls-mode-toggle-button\\:hover
  .react-page-controls-mode-toggle-button-description {
  max-width: 999px;
  background: rgba(0, 0, 0, 0.87);
  background: black;
  color: #ffffff;
  color: #ffffff;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border: 1px solid black;
}
.react-page-controls-mode-toggle-clearfix {
  clear: both;
}
@keyframes fadeIn {
  0% {
   opacity: 0;
   transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.react-page-controls-mode-toggle-control {
  z-index: 1;
  animation: fadeIn 0.8s forwards;
  opacity: 0;

}
.react-page-plugin-drawer-item {
  cursor: pointer;

  z-index: 1;
}
.react-page-plugin-drawer-item\\:hover {
  z-index: 2;
  box-shadow: 0 0 20px #ccc;
}
.react-page-controls-trash {
  position: fixed;
  bottom: -64px;
  z-index: 500;
  left: 50%;
  transition: bottom 200ms ease;
  padding: 8px;
}
.react-page-controls-trash.react-page-controls-trash-active {
  bottom: 16px;
}


.react-page-plugins-content-spacer
  > .react-resizable
  > .react-resizable-handle\\:before,
.react-page-plugins-content-spacer
  > .react-resizable
  > .react-resizable-handle\\:hover\\:before {
  content: ' ';
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
  right: 0;
  cursor: n-resize;
  line-height: 12px;
  font-size: 1.5em;
  height: 24px;
}
.react-page-editable .react-page-plugins-content-spacer {
  outline: 1px dashed black;
}
.react-page-editable-mode-preview .react-page-plugins-content-spacer {
  outline: none;
}

.react-page-plugins-content-image-placeholder {
  position: relative;
  width: 100%;
  text-align: center;
}`;
