import {
  SimpleForm,
  Edit,
  TextInput,
  BooleanInput,
  SelectInput,
} from "react-admin";

const UserEdit = (props: any) => (
  <Edit title="User edit" {...props}>
    <SimpleForm title="user">
      <TextInput source="email" label="" disabled />
      <SelectInput
        source="role"
        label="role"
        choices={[
          { id: "admin", name: "Admin" },
          { id: "user", name: "Brand team member" },
        ]}
        disabled
      />
      <BooleanInput source="isActive" disabled />
      <BooleanInput source="isEnabled" />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
