import ProductsList from "./ProductsList";
import ProductEdit from "./ProductEdit";
import ProductCreate from "./ProductCreate";

const products = {
  list: ProductsList,
  edit: ProductEdit,
  create: ProductCreate,
};

export default products;
