import styled from "styled-components";

interface LetterProps {
  orientation: "p" | "l";
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  bgColor?: string;
}

export const Letter = styled.div<LetterProps>`
  --rp-primary-color: ${(props) => props.primaryColor};
  --rp-secondary-color: ${(props) => props.secondaryColor};
  --rp-tertiary-color: ${(props) => props.tertiaryColor};

  --rp-primary-color-bg: ${(props) => props.primaryColor}80;
  --rp-secondary-color-bg: ${(props) => props.secondaryColor}80;
  --rp-tertiary-color-bg: ${(props) => props.tertiaryColor}80;

  --rp-black-color: #000000;
  --rp-grey-color: #999999;
  --rp-white-color: #ffffff;

  & .MuiPaper-root {
    width: ${(props) => (props.orientation === "p" ? "8.5in" : "11in")};
    height: ${(props) => (props.orientation === "p" ? "11in" : "8.5in")};
    margin-bottom: 4in;
    padding: 0 !important;
    overflow: scroll;
    background-color: ${(props) => (props.bgColor ? props.bgColor : "#FFFFF")};
  }

  & .slate {
    padding-bottom: 0;
    margin: 0;

    p {
      margin: 0;
    }
  }

  & .react-page-cell-inner {
    padding: 0;
  }
`;
