import { Datagrid, List, TextField, EditButton } from "react-admin";
import {
  OnlyCreateTopToolbar,
  DelWithConfTopToolbar,
} from "../common/TopToolbarActions";

export const ProductsList = (props: any) => (
  <List
    actions={<OnlyCreateTopToolbar />}
    bulkActionButtons={<DelWithConfTopToolbar name="products" />}
    {...props}
  >
    <Datagrid>
      <TextField source="product_name" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ProductsList;
