import {
  Edit,
  SimpleForm,
  TextInput,
  required as ReactAdminRequired,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  useGetList,
} from "react-admin";
import { useLocation } from "react-router";
import { Letter } from "../common/styled";
import { SectionTitle } from "../common/common";
import ReactPageInput from "./ReactPageInput";
import { SavePDFToolbar } from "../common/BottomToolbarActions";
import { useState } from "react";

const DocumentEdit = (props: any) => {
  const [rpReadOnly, setRpReadOnly] = useState(false);
  const [isConfig, setIsConfig] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userProductId = params.get("user_product_id");
  const redirect = `/user-products/${userProductId}/show`;

  const { data: configs } = useGetList(
    "configs",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { configs: userProductId }
  );

  const { data: templates } = useGetList(
    "user_templates",
    { page: 1, perPage: 100 },
    { field: "id", order: "DESC" },
    { product_id: props.id }
  );

  const translateConfigs = () => {
    return Object.keys(configs).map((key) => configs[key]);
  };
  const configChoices = translateConfigs();

  return (
    <Edit title="Create a Document" actions={false} {...props}>
      <SimpleForm
        label="document"
        toolbar={
          <SavePDFToolbar
            setReadOnly={() => {
              setRpReadOnly(true);
            }}
            {...props}
          />
        }
        redirect={redirect}
      >
        <FormDataConsumer>
          {({ formData }) => {
            let config = {
              primary_color: "",
              secondary_color: "",
              tertiary_color: "",
              bg_color: "",
            };
            if (formData.config_id) {
              // @ts-ignore
              const possibleConfig = configs[formData.config_id];

              if (possibleConfig) {
                // @ts-ignore
                config = possibleConfig;
                setIsConfig(true);
              }
            }

            return (
              <Letter
                primaryColor={config.primary_color}
                secondaryColor={config.secondary_color}
                tertiaryColor={config.tertiary_color}
                orientation={formData.orientation}
                bgColor={config.bg_color}
              >
                <TextInput
                  source="document_name"
                  fullWidth
                  validate={[ReactAdminRequired()]}
                />
                <SelectInput
                  source="config_id"
                  choices={configChoices}
                  optionText="config_name"
                />
                {isConfig ? (
                  <ArrayInput source="pages">
                    <SimpleFormIterator
                      getItemLabel={(index) => `${index + 1} page`}
                      disableReordering
                    >
                      <FormDataConsumer>
                        {({ formData, getSource }) => {
                          return (
                            <ReactPageInput
                              getSource={getSource}
                              orientation={formData.orientation}
                              templates={templates}
                              readonly={rpReadOnly}
                            />
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <SectionTitle
                    label="Please select config to continue"
                    variant="h6"
                    color="#9c27b0"
                  />
                )}
              </Letter>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default DocumentEdit;
