// import background, { ModeEnum } from "@react-page/plugins-background";
import divider from "@react-page/plugins-divider";
// import html5video from "@react-page/plugins-html5-video";
import { imagePlugin } from "@react-page/plugins-image";
import spacer from "@react-page/plugins-spacer";
// import video from "@react-page/plugins-video";

// import customContentPlugin from "./customContentPlugin";
// import customContentPluginWithListField from "./customContentPluginWithListField";
// import customLayoutPlugin from "./customLayoutPlugin";
// import customLayoutPluginWithInitialState from "./customLayoutPluginWithInitialState";
import { defaultSlate } from "./slate";
import customBackgroundGradient from "./customBackgroundGradient";
import colorImagePlugin from "./colorImage";
import contentWithImageTop from "./contentWithImageTop";
import contentWithImagesTop from "./contentWithImagesTop";
import contentAroundImageLeft from "./contentAroundImageLeft";
import contentAroundImageRight from "./contentAroundImageRight";
import paginationBar from "./paginationBar";
import headerSloganLogo from "./headerSloganLogo";
import headerLogo from "./headerLogo";
import footer from "./footer";
import footerWithGradient from "./footerWithGradient";
import colorImageWithContent from "./colorImageWithContent";
import { uploadFile } from "../utils/scripts";
import contentWithTitle from "./contentWithTitle";
import textContent from "./textContent";

const uploadImage: (url: string) => any = (defaultUrl) => async (file) => {
  const url = await uploadFile(file);
  return new Promise((resolve) => resolve({ url }));
};

// Define which plugins we want to use.

export const cellPlugins = [
  defaultSlate,
  customBackgroundGradient,
  spacer,
  colorImagePlugin,
  imagePlugin({ imageUpload: uploadImage("/images/react.png") }),
  // video,
  divider,
  contentWithImageTop,
  contentWithImagesTop,
  contentAroundImageLeft,
  contentAroundImageRight,
  colorImageWithContent,
  contentWithTitle,
  textContent,
  paginationBar,
  headerSloganLogo,
  headerLogo,
  footer,
  footerWithGradient,
  // headerSloganLogo,
  // footerAlignedRight
  // html5video,
  // customContentPlugin,
  // customContentPluginWithListField,

  // background({
  //   imageUpload: fakeImageUploadService("/images/sea-bg.jpg"),
  //   enabledModes:
  //     ModeEnum.IMAGE_MODE_FLAG |
  //     ModeEnum.GRADIENT_MODE_FLAG,
  // }),
  // customLayoutPlugin,
  // customLayoutPluginWithInitialState,
];
