import type { CellPlugin } from "@react-page/editor";

const containerStyle = {};

const titleStyle = {
  lineHeight: "100%",
  fontWeight: "700",
};

const contentStyle = {
  lineHeight: "110%",
  marginTop: "6%",
};

const contentWithTitle: CellPlugin<{
  title: string;
  titleColor: string;
  titleFontSize: string;
  contentColor: string;
  content: string;
  contentFontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{ ...containerStyle, padding: data.padding || "16px" }}>
      <div
        style={{
          ...titleStyle,
          color: `var(--rp-${data.titleColor}-color)`,
          fontSize: data.titleFontSize || "16px",
        }}
      >
        {data.title}
      </div>
      <div
        style={{
          ...contentStyle,
          color: `var(--rp-${data.contentColor}-color)`,
          fontSize: data.contentFontSize || "11px",
        }}
        dangerouslySetInnerHTML={{
          __html: data.content?.replace(/(?:\r\n|\r|\n)/g, "</br>"),
        }}
      />
    </div>
  ),
  id: "contentWithTitle",
  title: "Content with title",
  description: "Text content with headline.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        title: {
          type: "string",
          default: "Lorem ipsum",
        },
        titleColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "white", "grey", "black"],
        },
        titleFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          },
        },
        contentColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "white", "grey", "black"],
        },
        contentFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        },
      },
    },
  },
};

export default contentWithTitle;
