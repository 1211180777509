import type { CellPlugin } from "@react-page/editor";
import { FileUploadButton } from "../components/common/common";

const containerStyle = {};

const imgStyle = {
  width: "240px",
  height: "100px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center left",
};

const sloganStyle = {
  marginTop: "8px",
  fontWeight: "700",
  fontStyle: "italic",
};

const headerSloganLogo: CellPlugin<{
  imageUrl: string;
  slogan: string;
  sloganColor: string;
  sloganFontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{ ...containerStyle, padding: data.padding || "16px" }}>
      <div
        className=""
        style={{ ...imgStyle, backgroundImage: `url("${data.imageUrl}")` }}
      />
      <div
        style={{
          ...sloganStyle,
          color: `var(--rp-${data.sloganColor}-color)`,
          fontSize: data.sloganFontSize || "18px",
        }}
      >
        {data.slogan}
      </div>
    </div>
  ),
  id: "headerSloganLogo",
  title: "Header Logo-Slogan",
  description: "Header with logo image and slogan.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        imageUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        slogan: {
          type: "string",
          default: "Lorem ipsum",
        },
        sloganColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        sloganFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        },
      },
    },
  },
};

export default headerSloganLogo;
