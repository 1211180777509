import type { CellPlugin } from "@react-page/editor";

const containerStyle = {
  textAlign: 'right',
  boxSizing: 'border-box',
}

const pageNumberStyle = {
  fontWeight: 700,
  fontStyle: 'italic'
}

const paginationBar: CellPlugin<{
  content: string;
  textColor: string;
  backgroundColor: string;
  fontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{...containerStyle as any, background: `var(--rp-${data.backgroundColor}-color)`, padding: data.padding || '16px'}}>
      <div style={{...pageNumberStyle, color: `var(--rp-${data.textColor}-color)`, fontSize: data.fontSize || '11px'}}>{data.content}</div>
    </div>
  ),
  id: "footer",
  title: "Footer",
  description: "Footer with text content.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        content: {
          type: "string",
          default: "Lorem ipsum"
        },
        textColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
        backgroundColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
        fontSize: {
          type: "string",
          enum: ["10px", "11px", "12px", "14px", "16px", "18px", "22px", "24px", "32px", "48px", "64px"],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        }
      },
    },
  },
};

export default paginationBar;
