import {
  TopToolbar,
  CreateButton,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import slugify from "slugify";

export const OnlyCreateTopToolbar = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const DelWithConfTopToolbar = (props: any) => (
  <>
    <BulkDeleteWithConfirmButton
      confirmContent="Are you sure you want to proceed?"
      confirmTitle={`You are about to permanently delete ${props.name}.`}
      {...props}
    />
  </>
);

const orientations = {
  p: {
    h: 11,
    w: 8.5,
  },
  l: {
    h: 8.5,
    w: 11,
  },
};

export const DownloadPDFTopToolbar = (props: any) => {
  const downloadPDF = async () => {
    const dimensions = orientations[props.data.orientation];
    // @ts-ignore
    const papers: [HTMLElement] =
      document.getElementsByClassName("MuiPaper-root");
    const pdf = new jsPDF(props.data.orientation, "in", "letter");

    for (let i = 0; i < papers.length; i++) {
      const paper = papers[i];
      paper.style.boxShadow = "unset";
      await html2canvas(paper).then((canvas) => {
        // creates image out of HTMLElement
        const imgData = canvas.toDataURL("imp/jpeg");

        // if this is not first react-page paper
        // we need to add page to the pdf
        if (i !== 0) {
          pdf.addPage();
        }

        pdf.addImage(imgData, "JPEG", 0, 0, dimensions.w, dimensions.h);
      });
      paper.style.boxShadow =
        "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)";
    }
    pdf.save(slugify(props.data.template_name) + ".pdf");
  };

  return (
    <TopToolbar>
      <Button
        onClick={downloadPDF}
        variant="text"
        startIcon={<DownloadIcon />}
        sx={{ "&:hover": { color: "#9c27b0" } }}
      >
        download
      </Button>
    </TopToolbar>
  );
};
