import {
  TextInput,
  Edit,
  SimpleForm,
  required as ReactAdminRequired,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import { Box } from "@mui/material";
import { useLocation } from "react-router";
import { OnlySaveToolbar } from "../common/BottomToolbarActions";

const ConfigEdit = (props: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userProductId = params.get("user_product_id");
  const redirect = `/user-products/${userProductId}/show/1`;

  return (
    <Edit title="Edit a Config" {...props}>
      <SimpleForm
        label="config"
        toolbar={<OnlySaveToolbar />}
        redirect={redirect}
      >
        <TextInput
          source="config_name"
          validate={[ReactAdminRequired()]}
          fullWidth
        />
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box>
            <ColorInput
              source="primary_color"
              label="primary"
              validation={{ required: true }}
              validate={[ReactAdminRequired()]}
            />
          </Box>
          <Box sx={{ paddingLeft: "2rem" }}>
            <ColorInput
              source="secondary_color"
              label="secondary"
              validate={[ReactAdminRequired()]}
            />
          </Box>
          <Box sx={{ paddingLeft: "2rem" }}>
            <ColorInput
              source="tertiary_color"
              label="tertiary"
              validate={[ReactAdminRequired()]}
            />
          </Box>
          <Box sx={{ paddingLeft: "2rem" }}>
            <ColorInput source="bg_color" label="background" />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default ConfigEdit;
