import { Admin, Resource, fetchUtils } from "react-admin";
// import { demoFirsPage, demoSecondPage, demoThirdPage } from "./utils/demo";
import templates from "./components/Templates";
import users from "./components/Users";
import products from "./components/Products";
import user_products from "./components/UserProducts";
import configs from "./components/Configs";
import documents from "./components/Documents";
import {
  createGenerateClassName,
  StylesProvider,
} from "@material-ui/core/styles";
import { UserPoolId, ClientId } from "./auth/config";
import AuthProvider from "./auth/provider";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import jsonServerProvider from "ra-data-json-server";

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: "mui-ra",
});

const UserPool = new CognitoUserPool({
  UserPoolId,
  ClientId,
});

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  // add Bearer authorization token to the request
  const user = UserPool.getCurrentUser();
  const token =
    // @ts-ignore
    user.storage[
      // @ts-ignore
      `CognitoIdentityServiceProvider.${ClientId}.${user.username}.idToken`
    ];

  options.headers.set("Authorization", `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
  process.env.REACT_APP_API_URI,
  httpClient
);

const App = () => {
  const renderResources = (permission: string) => {
    switch (permission) {
      case "admin":
        return [
          <Resource name="templates" {...templates} />,
          <Resource name="users" {...users} />,
          <Resource name="products" {...products} />,
        ];
      case "user":
        return [
          <Resource
            name="user-products"
            options={{ label: "Your products" }}
            {...user_products}
          />,
          <Resource name="configs" {...configs} />,
          <Resource name="documents" {...documents} />,
          <Resource name="user_templates" />,
        ];
    }
  };

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Admin
        // @ts-ignore
        dataProvider={dataProvider}
        authProvider={AuthProvider({ UserPoolId, ClientId })}
        title="Rarify Learning"
      >
        {(permissions) => renderResources(permissions)}
      </Admin>
    </StylesProvider>
  );
};

export default App;
