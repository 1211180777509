import { pluginFactories } from "@react-page/plugins-slate";
import { Button } from "@mui/material";

export const setColorPlugin = pluginFactories.createComponentPlugin<{
  color: string;
}>({
  addHoverButton: true,
  addToolbarButton: true,
  type: "SetColor",
  object: "mark",
  icon: <Button sx={{ "&:hover": { color: "#9c27b0" } }}>Set Color</Button>,
  Component: "span",
  getStyle: ({ color }) => ({ color: `var(--rp-${color}-color)` }),
  controls: {
    type: "autoform",
    schema: {
      type: "object",
      properties: {
        color: {
          enum: ["primary", "secondary", "tertiary", "black", "white", "grey"],
          type: "string",
        },
      },
    },
  },
});

export const setFontPlugin = pluginFactories.createComponentPlugin<{
  font: string;
}>({
  addHoverButton: true,
  addToolbarButton: true,
  type: "SetFont",
  object: "mark",
  icon: <Button sx={{ "&:hover": { color: "#9c27b0" } }}>Set Font</Button>,
  Component: "span",
  getStyle: ({ font }) => ({ fontFamily: font }),
  controls: {
    type: "autoform",
    schema: {
      type: "object",
      properties: {
        font: {
          enum: [
            "Arial",
            "Verdana",
            "Helvetica",
            "Tahoma",
            "Times New Roman",
            "Georgia",
            "Garamond",
            "Courier New",
          ],
          type: "string",
        },
      },
    },
  },
});

export const setSize = pluginFactories.createComponentPlugin<{
  paddingTop: number;
  paddingRight: number;
  paddingBottom: number;
  paddingLeft: number;
  spaceBetweenLines: number;
  fontSize: number;
}>({
  addHoverButton: true,
  addToolbarButton: true,
  type: "Set Sizing",
  object: "mark",
  icon: <Button sx={{ "&:hover": { color: "#9c27b0" } }}>Set Sizing</Button>,
  Component: "p",
  getStyle: ({
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    spaceBetweenLines,
    fontSize,
  }) => ({
    padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
    fontSize: `${fontSize}px`,
    lineHeight: `${spaceBetweenLines}px`,
  }),
  controls: {
    type: "autoform",
    schema: {
      type: "object",
      properties: {
        paddingTop: {
          type: "integer",
          default: 0,
        },
        paddingRight: {
          type: "integer",
          default: 0,
        },
        paddingBottom: {
          type: "integer",
          default: 0,
        },
        paddingLeft: {
          type: "integer",
          default: 0,
        },
        spaceBetweenLines: {
          type: "integer",
        },
        fontSize: {
          type: "integer",
        },
      },
    },
  },
});
