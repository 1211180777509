import type { CellPlugin } from "@react-page/editor";

const containerStyle = {
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "space-between",
};

const pageNumberStyle = {
  fontWeight: 700,
  fontStyle: "italic",
};

const paginationBar: CellPlugin<{
  content: string;
  textColor: string;
  leftColor: string;
  rightColor: string;
  contentLeft: string;
  contentRight: string;
  fontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div
      style={{
        ...(containerStyle as any),
        background: `linear-gradient(to right, var(--rp-${data.leftColor}-color), var(--rp-${data.rightColor}-color))`,
        padding: data.padding || "16px",
      }}
    >
      <div
        style={{
          ...pageNumberStyle,
          color: `var(--rp-${data.textColor}-color)`,
          fontSize: data.fontSize || "11px",
        }}
      >
        {data.contentLeft}
      </div>
      <div
        style={{
          ...pageNumberStyle,
          color: `var(--rp-${data.textColor}-color)`,
          fontSize: data.fontSize || "11px",
        }}
      >
        {data.contentRight}
      </div>
    </div>
  ),
  id: "footerWithGradient",
  title: "Footer with gradient",
  description: "Footer with gradient and two text fields.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        textColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
        leftColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        rightColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        fontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        contentLeft: {
          type: "string",
          default: "Lorem ipsum dolot sit amet",
        },
        contentRight: {
          type: "string",
          default: "01",
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        },
      },
    },
  },
};

export default paginationBar;
