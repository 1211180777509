import FindInPageIcon from "@mui/icons-material/FindInPage";
import { Button } from "@mui/material";

interface SelectTemplateButtonProps {
  onClick: any;
}

const SelectTemplateButton = (props: SelectTemplateButtonProps) => (
  <Button
    onClick={props.onClick}
    variant="text"
    startIcon={<FindInPageIcon />}
    sx={{ "&:hover": { color: "#9c27b0" } }}
  >
    select template
  </Button>
);

export default SelectTemplateButton;
