import {
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  ImageList,
  ImageListItem,
} from "@mui/material";

interface Template {
  id: string;
  image: string;
  template_name: string;
  content: { content: object }[];
}

interface TemplateDialogProps {
  templates: Template[];
  isOpened: boolean;
  setValue: any;
  closeDialog: () => void;
  getSource: (source: string) => string;
}

const TemplateDialog = (props: TemplateDialogProps) => {
  const handleClick = (template: Template) => {
    props.setValue(props.getSource("content"), template.content[0]["content"]);
    props.closeDialog();
  };

  return (
    <Dialog
      open={props.isOpened}
      maxWidth="xl"
      fullWidth
      onClose={props.closeDialog}
    >
      <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
        Select template
      </DialogTitle>
      <DialogContent>
        {props.templates.length > 0 ? (
          <ImageList cols={3}>
            {props.templates.map((template) => (
              <ImageListItem
                key={template.id}
                sx={{
                  cursor: "pointer",
                  transition: "all .2s ease-in-out",
                  margin: "1em",
                  "&:hover": { transform: "scale(1.02)" },
                }}
              >
                <img
                  src={template.image}
                  alt={template.template_name}
                  loading="lazy"
                  onClick={(e) => handleClick(template)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Typography sx={{ color: "red" }} variant="h6" align="center">
            There are no available templates
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TemplateDialog;
