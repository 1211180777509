import type { CellPlugin } from "@react-page/editor";
import { FileUploadButton } from "../components/common/common";

const imgStyle = {
  width: '49%',
  height: '160px',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}

const imagesWrapperStyle = {
  display: 'flex',
  justifyContent: 'space-between'
}

const containerStyle = {
  padding: '16px',
  boxSizing: 'border-box'
}

const titleStyle = {
  margin: '16px 0 12px',
  fontWeight: 700,
  fontStyle: 'italic'
}

const contentStyle = {
  lineHeight: '110%'
}

const contentWithImagesTop: CellPlugin<{
  imageOneUrl: string;
  imageTwoUrl: string;
  title: string;
  titleColor: string;
  content: string;
  contentColor: string;
  titleFontSize: string;
  contentFontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{...containerStyle, padding: data.padding || '16px'} as any}>
      <div style={imagesWrapperStyle}>
        <div
          className="top-images-image-element"
          style={{...imgStyle, backgroundImage: `url("${data.imageOneUrl}")`} as any}
        />
        <div
          className="top-images-image-element"
          style={{...imgStyle, backgroundImage: `url("${data.imageTwoUrl}")`} as any}
        />
      </div>
      <div style={{...titleStyle, color: `var(--rp-${data.titleColor}-color)`, fontSize: data.titleFontSize || '18px'} as any}>
        {data.title}
      </div>
      <div
        style={{...contentStyle, color: `var(--rp-${data.contentColor}-color)`, fontSize: data.contentFontSize || '11px'} as any}
        dangerouslySetInnerHTML={{__html: data.content?.replace(/(?:\r\n|\r|\n)/g, '</br>')}}
      />
    </div>
  ),
  id: "contentWithImagesTop",
  title: "Content with two images (top)",
  description: "Text content with images at the top.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        imageOneUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        imageTwoUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        title: {
          type: "string",
          default: "Lorem ipsum",
        },
        titleColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        titleFontSize: {
          type: "string",
          enum: ["10px", "11px", "12px", "14px", "16px", "18px", "22px", "24px", "32px", "48px", "64px"],
        },
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          }
        },
        contentColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        contentFontSize: {
          type: "string",
          enum: ["10px", "11px", "12px", "14px", "16px", "18px", "22px", "24px", "32px", "48px", "64px"],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        }
      },
    },
  },
};

export default contentWithImagesTop;
