import html2canvas from "html2canvas";

export const createCanvas = async (reactPagePaper: HTMLElement) => {
    // box-shadow is not working properly with html2canvas
    reactPagePaper.style.boxShadow = "unset";
    let canvas;

    await html2canvas(reactPagePaper).then(async (canvas_) => {
        reactPagePaper.style.boxShadow = "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)";
        // image = canvas.toDataURL("imp/jpeg");
        canvas = canvas_;
    });

    return canvas;
};