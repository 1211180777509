import { useState } from "react";
import { RaReactPageInput } from "@react-page/react-admin";
import {
  Edit,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  useRecordContext,
} from "react-admin";
import { cellPlugins } from "../../slates/cellPlugins";
import { Letter } from "../common/styled";
import { SectionTitle } from "../common/common";
import { TemplateConfiguration } from "./common";
import { makeStyles } from "@material-ui/core/styles";
import { createCanvas } from "../../utils/image";
import S3FileUpload from "../../s3/ReactS3/S3FileUpload";

const galleryBucket = process.env.REACT_APP_TEMPLATE_GALLERY_BUCKET_NAME;

const toolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ourCellPlugins = [...cellPlugins];

const MyToolbar = (props: any) => {
  const record = useRecordContext();

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getCanvasBlob = (canvas: HTMLCanvasElement) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
  };

  const uploadImagePreview = async () => {
    let imagePath, image;

    // we need to set react-page readOnly to true in order
    // to take a screenshot
    await props.setReadOnly(true);
    await sleep(500); // react-page has some 'animations'

    // @ts-ignore
    const papers: [HTMLElement] =
      document.getElementsByClassName("MuiPaper-root");
    const canvas: HTMLCanvasElement = await createCanvas(papers[0]);

    await getCanvasBlob(canvas).then(
      (blob: Blob) => (image = new File([blob], `${record.id}.jpg`))
    );
    await S3FileUpload.uploadFile(image, galleryBucket).then(
      (data) => (imagePath = data.location)
    );

    return imagePath;
  };

  return (
    <Toolbar classes={toolbarStyles()} {...props}>
      <SaveButton disabled={props.pristine} />
      <SaveButton
        label="Save new version"
        type="button"
        transform={async (data) => ({
          ...data,
          image: await uploadImagePreview(),
          new_version: true,
        })}
        submitOnEnter={false}
      />
    </Toolbar>
  );
};

const TemplateEdit = (props: any) => {
  const [rpReadOnly, setRpReadOnly] = useState(false);

  return (
    <Edit title="Template edit" {...props}>
      <SimpleForm
        label="template"
        toolbar={<MyToolbar setReadOnly={setRpReadOnly} />}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Letter
                orientation={formData.orientation}
                primaryColor={formData.primary_color}
                secondaryColor={formData.secondary_color}
                tertiaryColor={formData.tertiary_color}
                bgColor={formData.bg_color}
              >
                <TextInput source="template_name" fullWidth />
                <BooleanInput source="is_draft" disabled />
                <TemplateConfiguration />
                <br />
                <SectionTitle label="TEMPLATE" variant="h4" color="#3f51b5" />
                <ArrayInput source="content" label="">
                  <SimpleFormIterator
                    getItemLabel={(index) => `${index + 1} page`}
                    disableReordering
                  >
                    <FormDataConsumer>
                      {({ formData, getSource }) => {
                        return (
                          <RaReactPageInput
                            label="page"
                            source={getSource("content")}
                            cellPlugins={ourCellPlugins}
                            zoomEnabled={false}
                            sidebarPosition="rightRelative"
                            readOnly={rpReadOnly}
                          />
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Letter>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default TemplateEdit;
