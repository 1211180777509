import type { CellPlugin } from "@react-page/editor";

const contentStyle = {
  fontSize: '16px'
}

const customBackgroundGradient: CellPlugin<{
  leftColor: string;
  rightColor: string;
  content: string;
  color: string;
  fontSize: string;
  align: string;
}> = {
  Renderer: ({ children, data }) => (
    <div
      style={{
        padding: '48px 16px 8px',
        background: `linear-gradient(to right, var(--rp-${data.leftColor}-color), var(--rp-${data.rightColor}-color))`,
        textAlign: data.align as any
      }}
    >
      <div
        style={{...contentStyle, color: `var(--rp-${data.color}-color)`, fontSize: data.fontSize || '11px'} as any}
        dangerouslySetInnerHTML={{__html: data.content?.replace(/(?:\r\n|\r|\n)/g, '</br>')}}
      />
    </div>
  ),
  id: "background-gradient-plugin",
  title: "Background gradient",
  description: "Add linear gradient to your page.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        leftColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        rightColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          }
        },
        color: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
        fontSize: {
          type: "string",
          enum: ["10px", "11px", "12px", "14px", "16px", "18px", "22px", "24px", "32px", "48px", "64px"],
        },
        align: {
          type: "string",
          enum: ["left", "right", "center"],
        },
      },
    },
  },
};

export default customBackgroundGradient;
