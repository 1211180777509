import {
  SimpleForm,
  Edit,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

const ProductEdit = (props: any) => {
  return (
    <Edit title="Product edit" {...props}>
      <SimpleForm title="product">
        <TextInput source="product_name" />
        <ReferenceArrayInput
          source="users"
          reference="users"
          filter={{ role: "user" }}
          perPage={1000}
        >
          <AutocompleteArrayInput optionText="email" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default ProductEdit;
