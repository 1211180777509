import { RaReactPageInput } from "@react-page/react-admin";
import { useForm } from "react-final-form";
import { cellPlugins } from "../../slates/cellPlugins";
import { useState } from "react";
import TemplateDialog from "./TemplateDialog";
import SelectTemplateButton from "./SelectTemplateButton";

const ourCellPlugins = [...cellPlugins];

interface ReactPageInputProps {
  getSource: (source: string) => string;
  orientation: "p" | "l";
  templates: any;
  readonly?: boolean;
}

const ReactPageInput = (props: ReactPageInputProps) => {
  const { change } = useForm();
  const [modal, setModal] = useState(false);

  const templates = Object.keys(props.templates).map(
    (key) => props.templates[key]
  );

  return (
    <>
      {!props.readonly ? (
        <>
          <SelectTemplateButton onClick={(e) => setModal(true)} />
          <TemplateDialog
            isOpened={modal}
            templates={templates}
            setValue={change}
            closeDialog={() => setModal(false)}
            getSource={props.getSource}
          />
        </>
      ) : (
        <></>
      )}
      <RaReactPageInput
        source={props.getSource("content")}
        label="page"
        cellPlugins={ourCellPlugins}
        zoomEnabled={false}
        sidebarPosition="rightRelative"
        readOnly={props.readonly}
      />
    </>
  );
};

export default ReactPageInput;
