import { RaReactPageInput } from "@react-page/react-admin";
import {
  Show,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
} from "react-admin";
import { cellPlugins } from "../../slates/cellPlugins";
import { Letter } from "../common/styled";
import { SectionTitle } from "../common/common";
import { TemplateConfiguration } from "./common";

const ourCellPlugins = [...cellPlugins];

const TemplateShow = (props: any) => (
  <Show title="Template preview" {...props}>
    <SimpleForm label="template" toolbar={false}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Letter
              orientation={formData.orientation}
              primaryColor={formData.primary_color}
              secondaryColor={formData.secondary_color}
              tertiaryColor={formData.tertiary_color}
              bgColor={formData.bg_color}
            >
              <TextInput source="template_name" disabled fullWidth />
              <BooleanInput source="is_draft" disabled />
              <TemplateConfiguration />
              <br />
              <SectionTitle label="TEMPLATE" variant="h4" color="#3f51b5" />
              <ArrayInput source="content" label="">
                <SimpleFormIterator
                  getItemLabel={(index) => `${index + 1} page`}
                  disableRemove
                  disableAdd
                  disableReordering
                >
                  <FormDataConsumer>
                    {({ formData, getSource }) => {
                      return (
                        <RaReactPageInput
                          label="page"
                          cellPlugins={ourCellPlugins}
                          source={getSource("content")}
                          readOnly
                          zoomEnabled={false}
                          sidebarPosition="rightRelative"
                        />
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Letter>
          );
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Show>
);

export default TemplateShow;
