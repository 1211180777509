import { v4 as uuidv4 } from "uuid";
import S3FileUpload from "../s3/ReactS3/S3FileUpload";

const galleryBucket = process.env.REACT_APP_TEMPLATE_GALLERY_BUCKET_NAME;

export const uploadFile = (file: File) => {
  return S3FileUpload.uploadFile(file, galleryBucket, null, uuidv4()).then(
    (data) => {
      console.log(data.location)
      return data.location;
    }
  );
};
