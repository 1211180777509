import type { CellPlugin } from "@react-page/editor";
import { FileUploadButton } from "../components/common/common";

const imgStyle = {
  float: "left",
  marginRight: "16px",
  marginBottom: "16px",
  width: "200px",
  height: "140px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const containerStyle = {
  boxSizing: "border-box",
};

const titleStyle = {
  padding: "0",
  marginBottom: "8px",
  fontWeight: 700,
  fontStyle: "italic",
};

const contentStyle = {
  lineHeight: "110%",
  marginTop: "16px",
};

const contentAroundImageLeft: CellPlugin<{
  imageUrl: string;
  title: string;
  titleColor: string;
  content: string;
  contentColor: string;
  titleFontSize: string;
  contentFontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{ ...containerStyle, padding: data.padding || "16px" } as any}>
      <div
        className="image"
        style={
          { ...imgStyle, backgroundImage: `url("${data.imageUrl}")` } as any
        }
      />
      <div
        style={
          {
            ...titleStyle,
            color: `var(--rp-${data.titleColor}-color)`,
            fontSize: data.titleFontSize || "18px",
          } as any
        }
      >
        {data.title}
      </div>
      <div
        style={{
          ...contentStyle,
          color: `var(--rp-${data.contentColor}-color)`,
          fontSize: data.contentFontSize || "11px",
        }}
        dangerouslySetInnerHTML={{
          __html: data.content?.replace(/(?:\r\n|\r|\n)/g, "</br>"),
        }}
      />
    </div>
  ),
  id: "contentAroundImageLeft",
  title: "Content around image (left)",
  description: "Text content around the image from the right side.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        imageUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        title: {
          type: "string",
          default: "Lorem ipsum",
        },
        titleColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        titleFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          },
        },
        contentColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey"],
        },
        contentFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        },
      },
    },
  },
};

export default contentAroundImageLeft;
