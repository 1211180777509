import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required as ReactAdminRequired,
} from "react-admin";
import { TemplateConfiguration } from "./common";

const TemplateCreate = (props: any) => (
  <Create title="Create a Template" {...props}>
    <SimpleForm label="template">
      <TextInput
        source="template_name"
        fullWidth
        validate={[ReactAdminRequired()]}
      />
      <SelectInput
        source="orientation"
        label="orientation"
        choices={[
          { id: "p", name: "portrait" },
          { id: "l", name: "landscape" },
        ]}
        validate={[ReactAdminRequired()]}
        fullWidth
      />
      <TemplateConfiguration />
    </SimpleForm>
  </Create>
);

export default TemplateCreate;
