import { useTranslate } from "react-admin";
import { Button, Typography } from "@mui/material";
import { connectField } from "uniforms";
import { uploadFile } from "../../utils/scripts";

interface SectionTitleProps {
  label: string;
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: string;
}

export const SectionTitle = (props: SectionTitleProps) => {
  const translate = useTranslate();

  return (
    <Typography
      variant={props.variant}
      gutterBottom
      sx={props.color ? { color: props.color } : undefined}
    >
      {translate(props.label)}
    </Typography>
  );
};

export const FileUploadButton = connectField(({ onChange }) => {
  return (
    <Button variant="contained" component="label" style={{marginRight: '16px'}}>
      Upload File
      <input
        type="file"
        hidden
        onChange={async (e) => {
          const url = await uploadFile(e.target.files[0]);
          // @ts-ignore
          onChange(url);
        }}
      />
    </Button>
  );
});
