import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const AddConfigButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    // @ts-ignore
    variant="raised"
    component={Link}
    to={`/configs/create?user_product_id=${record.id}`}
    label="Add a config"
    title="Add a config"
  >
    <ColorLensIcon />
  </Button>
);

export default withStyles(styles)(AddConfigButton);
