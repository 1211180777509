import {
  Datagrid,
  List,
  TextField,
  SelectField,
  BooleanField,
  EditButton,
} from "react-admin";
import {
  OnlyCreateTopToolbar,
  DelWithConfTopToolbar,
} from "../common/TopToolbarActions";

export const UserList = (props: any) => (
  <List
    actions={<OnlyCreateTopToolbar />}
    bulkActionButtons={<DelWithConfTopToolbar name="users" />}
    {...props}
  >
    <Datagrid>
      <TextField source="email" />
      <SelectField
        source="role"
        choices={[
          { id: "admin", name: "Admin" },
          { id: "user", name: "Brand team member" },
        ]}
      />
      <BooleanField source="isActive" />
      <BooleanField source="isEnabled" />
      <EditButton />
    </Datagrid>
  </List>
);

export default UserList;
