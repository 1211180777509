import {
  Datagrid,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  EditButton,
  FunctionField,
  DeleteButton,
  SelectField,
  ShowButton,
} from "react-admin";
import { ColorField } from "react-admin-color-input";
import AddDocumentButton from "./buttons/AddDocumentButton";
import AddConfigButton from "./buttons/AddConfigButton";

const UserProductShow = (props: any) => (
  <Show {...props} title="Your product">
    <TabbedShowLayout>
      <Tab label="documents">
        <ReferenceManyField
          reference="documents"
          addLabel={false}
          target="documents"
        >
          <Datagrid>
            <TextField source="document_name" />
            <SelectField
              source="orientation"
              choices={[
                { id: "p", name: "portrait" },
                { id: "l", name: "landscape" },
              ]}
            />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <FunctionField
              render={(record) => (
                <EditButton
                  to={`/documents/${record.id}?user_product_id=${props.id}`}
                ></EditButton>
              )}
            />
            <FunctionField
              render={(record) => (
                <ShowButton
                  to={`/documents/${record.id}/show?user_product_id=${props.id}`}
                ></ShowButton>
              )}
            />
            <DeleteButton redirect={`/user-products/${props.id}/show`} />
          </Datagrid>
        </ReferenceManyField>
        <AddDocumentButton record={props} />
      </Tab>
      <Tab label="configs">
        <ReferenceManyField
          reference="configs"
          addLabel={false}
          target="configs"
        >
          <Datagrid>
            <TextField source="config_name" />
            <ColorField source="primary_color" />
            <ColorField source="secondary_color" />
            <ColorField source="tertiary_color" />
            <FunctionField
              render={(record) => (
                <EditButton
                  to={`/configs/${record.id}?user_product_id=${props.id}`}
                ></EditButton>
              )}
            />
            <DeleteButton redirect={`/user-products/${props.id}/show/1`} />
          </Datagrid>
        </ReferenceManyField>
        <AddConfigButton record={props} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default UserProductShow;
