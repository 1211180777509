import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  required as ReactAdminRequired,
} from "react-admin";

const UserCreate = (props: any) => (
  <Create title="Create a User" {...props}>
    <SimpleForm label="user">
      <TextInput source="email" fullWidth validate={[ReactAdminRequired()]} />
      <SelectInput
        source="role"
        label="role"
        choices={[
          { id: "admin", name: "Admin" },
          { id: "user", name: "Brand team member" },
        ]}
        validate={[ReactAdminRequired()]}
        fullWidth
      />
    </SimpleForm>
  </Create>
);

export default UserCreate;
