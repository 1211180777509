import {
  Datagrid,
  EditButton,
  List,
  ShowButton,
  TextField,
  BooleanField,
} from "react-admin";
import {
  OnlyCreateTopToolbar,
  DelWithConfTopToolbar,
} from "../common/TopToolbarActions";

const TemplateList = (props: any) => (
  <List
    actions={<OnlyCreateTopToolbar />}
    bulkActionButtons={<DelWithConfTopToolbar name="templates" />}
    {...props}
  >
    <Datagrid>
      <TextField source="template_name" />
      <BooleanField source="is_public" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

export default TemplateList;
