import "@react-page/editor/lib/index.css";
import "@react-page/plugins-video/lib/index.css";
import "@react-page/plugins-spacer/lib/index.css";
import "@react-page/plugins-image/lib/index.css";
import "@react-page/plugins-html5-video/lib/index.css";
import "@react-page/plugins-background/lib/index.css";
import "@react-page/plugins-slate/lib/index.css";
import "./styles/styles.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
