import type { CellPlugin } from "@react-page/editor";
import {FileUploadButton} from "../components/common/common";

const containerStyle = {
  padding: '16px',
  display: 'flex'
}

const logoStyle = {
  fontSize: '16px',
  width: '240px',
  height: '100px',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
}

const customBackgroundGradient: CellPlugin<{
  imgUrl: string;
  backgroundColor: string;
  align: string;
}> = {
  Renderer: ({ children, data }) => (
    <div
      style={{
        ...containerStyle,
        textAlign: data.align as any,
        background: `var(--rp-${data.backgroundColor}-color)`,
        justifyContent: data.align
      }}
    >
      <div
        style={{...logoStyle, backgroundImage: `url("${data.imgUrl}")`}}
      />
    </div>
  ),
  id: "headerLogo",
  title: "Header with logo",
  description: "",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        imgUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        align: {
          type: "string",
          enum: ["left", "right", "center"],
        },
        backgroundColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "black", "grey", "white"],
        },
      },
    },
  },
};

export default customBackgroundGradient;
