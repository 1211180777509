import { Styles } from "./styles";
import * as csstree from "css-tree";

const getPropertyValue = (html, valueName) => {
  return getComputedStyle(html).getPropertyValue(valueName);
};

const replaceVariables = (HTML) => {
  const variablesToReplace = [
    "--black",
    "--white",
    "--transparent",
    "--fullBlack",
    "--darkBlack",
    "--lightBlack",
    "--minBlack",
    "--faintBlack",
    "--fullWhite",
    "--darkWhite",
    "--lightWhite",
    "--minWhite",
    "--faintWhite",
    "--grey300",
    "--grey900",
    "--rp-primary-color",
    "--rp-secondary-color",
    "--rp-tertiary-color",
    "--rp-undefined-color",
    "--rp-black-color",
    "--rp-grey-color",
    "--rp-white-color",
  ];

  let htmlStringReplaced = new XMLSerializer().serializeToString(HTML);

  variablesToReplace.forEach((variableName) => {
    const contentToReplace = `var\\(${variableName}\\)`;
    const re = new RegExp(contentToReplace, "g");
    htmlStringReplaced = htmlStringReplaced.replaceAll(
      re,
      getPropertyValue(HTML, variableName)
    );
  });

  return htmlStringReplaced;
};

const removeUnnecessaryFormulas = (HTMLString) => {
  let htmlStringReplaced = HTMLString;

  const formulasToRemove = ["white-space: pre-wrap;", "flex:1 1;", "<p></p>"];
  formulasToRemove.forEach((formula) => {
    htmlStringReplaced = htmlStringReplaced.replaceAll(formula, "");
  });

  return htmlStringReplaced;
};

const addGlobalStyles = (HTMLString) => {
  return HTMLString.replace(
    "<body>",
    "<body><style>body { margin: 0; padding: 0; -webkit-print-color-adjust: exact; } p {margin: 0; padding: 0; }</style>"
  );
};

const escapeHTML = (htmlStr) => {
  return htmlStr
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
};

export const addInlineStyles = (html) => {
  const ast = csstree.parse(Styles);
  const stylesMap = {};

  let currentSelector = csstree.find(
    ast,
    (node) => node.type === "ClassSelector"
  ).name;

  csstree.walk(ast, (globalNode) => {
    if (globalNode.type === "ClassSelector") {
      currentSelector = globalNode.name;
    }

    if (globalNode.type === "SelectorList") {
      const selectorListItems = [
        csstree.find(ast, (node) => node.type === "ClassSelector").name,
      ];

      csstree.walk(ast, (selectorListNode) => {
        if (globalNode.type === "ClassSelector") {
          selectorListItems.push(selectorListNode.name);
        }
      });

      currentSelector = selectorListItems.join(" .");
    }

    if (globalNode.type === "Declaration") {
      const declaration = csstree.generate(globalNode);

      stylesMap[currentSelector] = !stylesMap[currentSelector]
        ? [declaration]
        : [...stylesMap[currentSelector], declaration];
    }
  });

  // entry styles
  html.setAttribute(
    "style",
    `${html.getAttribute("style")};margin:0; padding:0`
  );

  // remove empty elements
  html.querySelectorAll(":not(img)").forEach((element) => {
    if (!element.hasChildNodes() && !element.getAttribute("style"))
      element.parentNode.removeChild(element);
  });
  //
  // //
  // newHTML.querySelectorAll('div, span, p').forEach(element => {
  //   const currentStyles = element.getAttribute('style');
  //   element.setAttribute('style', `height:100%;${currentStyles ? currentStyles : ''};`);
  // });
  //
  // //
  html.querySelectorAll("img").forEach((element) => {
    const currentStyles = element.getAttribute("style");
    element.setAttribute(
      "style",
      `max-width:100%;max-height:100%;${currentStyles ? currentStyles : ""};`
    );
  });

  // fix for alignment - header
  html.querySelectorAll("p").forEach((element) => {
    if (element.style.textAlign === "right") {
      const nextSiblingCurrentStyles =
        element.nextElementSibling?.getAttribute("style");
      element.nextElementSibling?.setAttribute(
        "style",
        `${nextSiblingCurrentStyles};text-align: right;`
      );
    }
  });

  // fix for alignment - footer
  html.querySelectorAll("p").forEach((element) => {
    if (element.getAttribute("font-size") === "8") {
      const currentStyles = element.getAttribute("style");
      element.setAttribute("style", `${currentStyles};margin: 8px;`);
    }
  });

  // entry for p
  html.querySelectorAll("p").forEach((element) => {
    const currentStyles = element.getAttribute("style");
    element.setAttribute(
      "style",
      `${currentStyles};padding: 0 16px; margin: 0`
    );
  });

  html.querySelectorAll("span").forEach((element) => {
    if (element.innerHTML === "01") {
      const currentStyles = element.getAttribute("style");
      element.setAttribute(
        "style",
        `${currentStyles};display: block; margin-top: 8px;`
      );
    }
  });

  // component: content with images top
  html.querySelectorAll(".top-images-image-element").forEach((element) => {
    element.setAttribute(
      "style",
      `${element.getAttribute("style")};max-width:49%`
    );
  });

  // // fix for header
  // // background: linear-gradient
  // newHTML.querySelectorAll('div').forEach(element => {
  //   if (element?.getAttribute('style')?.includes('linear-gradient') ){
  //     const currentStyles = element?.getAttribute('style');
  //     element.nextElementSibling?.setAttribute('style', `${currentStyles};height: 100%;`)
  //   }
  // });

  Object.keys(stylesMap).forEach((className) => {
    // we don't want to add class for xs views
    if (!className.match(/^react-page-cell-xs-*/)) {
      const elements = html.querySelectorAll(`.${className}`);
      elements.forEach((element) => {
        const currentStyles = element.getAttribute("style");
        element.setAttribute(
          "style",
          `${currentStyles ? currentStyles : ""};${stylesMap[className].join(
            ";"
          )}`
        );
      });
    }
  });

  const htmlStringWithValue = replaceVariables(html);
  const newHTMLString = addGlobalStyles(
    removeUnnecessaryFormulas(htmlStringWithValue)
  );

  return {
    html: newHTMLString,
    htmlEscaped: escapeHTML(newHTMLString),
    css: Styles.replaceAll("\n", ""),
  };
};
