import type { CellPlugin } from "@react-page/editor";
import { FileUploadButton } from "../components/common/common";

const containerStyle = {};

const titleStyle = {
  fontWeight: "700",
  marginTop: "16px",
};

const contentStyle = {
  lineHeight: "110%",
  marginTop: "16px",
};

const imgStyle = {
  height: "160px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
};

const overlayStyle = {
  position: "absolute",
  height: "160px",
  width: "100%",
  left: "0",
  top: "0",
  zIndex: "1",
  opacity: "0.6",
};

const colorImagePlugin: CellPlugin<{
  color: string;
  imageUrl: string;
  imageColor: string;
  title: string;
  titleColor: string;
  contentColor: string;
  content: string;
  titleFontSize: string;
  contentFontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => (
    <div style={{ ...containerStyle, padding: data.padding || "16px" }}>
      <div
        style={
          { ...imgStyle, backgroundImage: `url("${data.imageUrl}")` } as any
        }
      >
        <div
          style={
            {
              ...overlayStyle,
              background: `var(--rp-${data.imageColor}-color)`,
            } as any
          }
        />
      </div>
      <div
        style={{
          ...titleStyle,
          color: `var(--rp-${data.titleColor}-color)`,
          fontSize: data.titleFontSize || "16px",
        }}
      >
        {data.title}
      </div>
      <div
        style={{
          ...contentStyle,
          color: `var(--rp-${data.contentColor}-color)`,
          fontSize: data.contentFontSize || "11px",
        }}
        dangerouslySetInnerHTML={{
          __html: data.content?.replace(/(?:\r\n|\r|\n)/g, "</br>"),
        }}
      />
    </div>
  ),
  id: "colorImageWithContent",
  title: "Color Image With Content",
  description: "Text content with colored imaged at the top.",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        imageUrl: {
          type: "string",
          uniforms: {
            component: FileUploadButton,
          },
        },
        imageColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary"],
        },
        title: {
          type: "string",
          default: "Lorem ipsum",
        },
        titleFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        titleColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "white", "grey", "black"],
        },
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          },
        },
        contentColor: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "white", "grey", "black"],
        },
        contentFontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
          default: "16px",
        },
      },
    },
  },
};

export default colorImagePlugin;
