import type { CellPlugin } from "@react-page/editor";

const containerStyle = {};

const contentStyle = {
  lineHeight: "110%",
};

const contentWithTitle: CellPlugin<{
  color: string;
  content: string;
  fontSize: string;
  padding: string;
}> = {
  Renderer: ({ data }) => {
    return (
      <div style={{ ...containerStyle, padding: data.padding || "16px" }}>
        <div
          style={{
            ...contentStyle,
            color: `var(--rp-${data.color}-color)`,
            fontSize: data.fontSize || "11px",
          }}
          dangerouslySetInnerHTML={{
            __html: data.content?.replace(/(?:\r\n|\r|\n)/g, "</br>"),
          }}
        />
      </div>
    );
  },
  id: "textContent",
  title: "Text content",
  description: "",
  version: 1,
  controls: {
    type: "autoform",
    schema: {
      required: [],
      properties: {
        content: {
          type: "string",
          default: "Lorem ipsum dolor sit amet",
          uniforms: {
            multiline: true,
          },
        },
        color: {
          type: "string",
          enum: ["primary", "secondary", "tertiary", "white", "grey", "black"],
        },
        fontSize: {
          type: "string",
          enum: [
            "10px",
            "11px",
            "12px",
            "14px",
            "16px",
            "18px",
            "22px",
            "24px",
            "32px",
            "48px",
            "64px",
          ],
        },
        padding: {
          type: "string",
          enum: ["4px", "8px", "12px", "16px", "24px", "32px"],
        },
      },
    },
  },
};

export default contentWithTitle;
