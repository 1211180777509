import {
  Create,
  SimpleForm,
  TextInput,
  required as ReactAdminRequired,
  SelectInput,
} from "react-admin";
import { useLocation } from "react-router";

const DocumentCreate = (props: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userProductId = params.get("user_product_id");
  const redirect = `/user-products/${userProductId}/show`;

  return (
    <Create title="Create a Document" {...props}>
      <SimpleForm
        label="document"
        defaultValue={{ user_product_id: userProductId }}
        redirect={redirect}
      >
        <TextInput
          source="document_name"
          fullWidth
          validate={[ReactAdminRequired()]}
        />
        <SelectInput
          source="orientation"
          label="orientation"
          choices={[
            { id: "p", name: "portrait" },
            { id: "l", name: "landscape" },
          ]}
          validate={[ReactAdminRequired()]}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default DocumentCreate;
