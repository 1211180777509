import TemplateList from "./TemplatesList";
import TemplateEdit from "./TemplateEdit";
import TemplateCreate from "./TemplateCreate";
import TemplateShow from "./TemplateShow";

const templates = {
  list: TemplateList,
  edit: TemplateEdit,
  create: TemplateCreate,
  show: TemplateShow,
};

export default templates;
