import {
  Create,
  SimpleForm,
  TextInput,
  required as ReactAdminRequired,
} from "react-admin";

const ProductCreate = (props: any) => (
  <Create title="Create a Product" {...props}>
    <SimpleForm label="product" redirect={`/products`}>
      <TextInput
        source="product_name"
        fullWidth
        validate={[ReactAdminRequired()]}
      />
    </SimpleForm>
  </Create>
);

export default ProductCreate;
