import { ColorInput } from "react-admin-color-input";
import { SectionTitle } from "../common/common";
import { Box } from "@mui/material";
import { required as ReactAdminRequired } from "react-admin";

export const TemplateConfiguration = () => (
  <>
    <SectionTitle label="TEMPLATE CONFIGURATION" variant="h4" color="#3f51b5" />
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box>
        <ColorInput
          source="primary_color"
          label="primary"
          validation={{ required: true }}
          validate={[ReactAdminRequired()]}
        />
      </Box>
      <Box sx={{ paddingLeft: "2rem" }}>
        <ColorInput
          source="secondary_color"
          label="secondary"
          validate={[ReactAdminRequired()]}
        />
      </Box>
      <Box sx={{ paddingLeft: "2rem" }}>
        <ColorInput
          source="tertiary_color"
          label="tertiary"
          validate={[ReactAdminRequired()]}
        />
      </Box>
      <Box sx={{ paddingLeft: "2rem" }}>
        <ColorInput source="bg_color" label="background" />
      </Box>
    </Box>
  </>
);
