import DocumentCreate from "./DocumentCreate";
import DocumentEdit from "./DocumentEdit";
import DocumentShow from "./DocumentShow";

const documents = {
  create: DocumentCreate,
  edit: DocumentEdit,
  show: DocumentShow,
};

export default documents;
