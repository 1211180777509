import { useState } from "react";
import { TopToolbar, useRecordContext, useNotify } from "react-admin";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import axiosInstance, { getToken } from "../../axios";

const DownloadPDFTopToolbar = (props: any) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();

  const DownloadPDF = async () => {
    setButtonDisabled(true);
    notify("You PDF will be downloaded soon.", {
      type: "info",
    });
    await axiosInstance
      .get(`${process.env.REACT_APP_API_URI}/pdf/${record.id}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then((result) => {
        const a = document.createElement("a");
        a.href = result.data["location"];
        a.target = "_blank";

        a.click();
      })
      .catch((error) => {
        notify("Unable to generate PDF now, please try again later", {
          type: "error",
        });
      });
    setButtonDisabled(false);
  };

  return (
    <TopToolbar>
      {record && record.pages ? (
        <Button
          onClick={DownloadPDF}
          variant="text"
          startIcon={<DownloadIcon />}
          sx={{ "&:hover": { color: "#9c27b0" } }}
          disabled={buttonDisabled}
        >
          Download pdf
        </Button>
      ) : (
        <></>
      )}
    </TopToolbar>
  );
};

export default DownloadPDFTopToolbar;
