import { Datagrid, List, TextField, ShowButton } from "react-admin";

const UserProductsList = (props: any) => (
  <List actions={null} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField source="product_name" />
      <ShowButton />
    </Datagrid>
  </List>
);

export default UserProductsList;
